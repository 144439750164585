
import Vue from "vue";
import { InformationData } from "@/types/interfaces";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "CoursePublish",
  props: {
    isPublished: Boolean,
    isAlreadyAgreed: Boolean,
    data: Object,
    url: String,
    languages: Array,
    platforms: Array,
    audiences: Array,
    skipOverview: Boolean
  },
  data() {
    return {
      infoData: [
        {
          name: "start_date",
          label: (this as any).$t("courseHero.infoDataStartDate"),
          icon: "calendar"
        },
        {
          name: "seat_price",
          label: (this as any).$t("courseHero.labelPricePerSeat"),
          icon: "outline-paid"
        },
        {
          name: "start_time",
          label: (this as any).$t("courseHero.infoDataStartTime"),
          icon: "time-square"
        },
        {
          name: "max_seats",
          label: (this as any).$t("courseHero.infoDataAvailableSeats"),
          icon: "seat-block"
        },
        {
          name: "duration",
          label: (this as any).$t("courseHero.infoDataSessionDuration"),
          icon: "time-circle",
          sufix: "Min"
        },
        {
          name: "language_id",
          label: (this as any).$t("courseHero.infoDataLanguage"),
          icon: "language"
        },
        {
          name: "mentoring_platform_id",
          label: (this as any).$t("courseHero.infoDataPlatform"),
          icon: "platform"
        },
        {
          name: "course_audience_id",
          label: (this as any).$t("courseHero.infoDataAudience"),
          icon: "3users"
        },
        {
          name: "is_certificate",
          label: (this as any).$t("courseHero.infoDataCertificates"),
          icon: "education"
        },
        {
          name: "has_guest_users",
          label: (this as any).$t("courseHero.infoDataAllowGuests"),
          icon: "non-user"
        }
      ] as InformationData[],
      publishInformation: [
        {
          icon: "edit",
          text: (this as any).$t("courseHero.publishInformationEditMessage")
        },
        {
          icon: "seat-block",
          text: (this as any).$t(
            "courseHero.publishInformationSeatBlockMessage"
          )
        },
        {
          icon: "time-circle",
          text: (this as any).$t(
            "courseHero.publishInformationTimeEditLimitMessage"
          )
        },
        {
          icon: "close",
          text: (this as any).$t("courseHero.publishInformationCloseMessage")
        }
      ],
      earningsDetails: {
        seat_price: {
          label: (this as any).$t("courseHero.earningsDetailsSeatPrice"),
          icon: "seat-block",
          price: true
        },
        mentorice_fee: {
          label: (this as any).$t("courseHero.earningsDetailsMentoriceFee"),
          icon: "mentorice",
          percent: true
        },
        donations: {
          label: (this as any).$t("courseHero.earningsDetailsDonations"),
          icon: "donations",
          percent: true
        },
        zoom_fee: {
          label: (this as any).$t("courseHero.earningsDetailsZoomFee"),
          icon: "zoom",
          percent: true,
          cb: () => {
            //@ts-ignore
            return this.isMentoriceZoom();
          }
        } as any
      } as any,
      overview: {},
      i_agree: false,
      submitBtnLoading: false
    };
  },
  computed: {
    isZeroPrice(): any {
      return this.data.seat_price <= 0;
    },
    ...mapGetters({
      termsAndConditionLink: "helper/getTermsAndConditionLink",
      platformCurrency: "helper/getDefaultPlatformCurrency",
      paymentTermsLink: "helper/getPaymentTermsLink"
    })
  },
  mounted() {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
    this.$store
      .dispatch("course/FINANCIAL_OVERVIEW", {
        course_id: this.$route.params?.id,
        seat_price: this.data.seat_price,
        duration: this.data.duration,
        max_seats: this.data.max_seats,
        hasZoom: this.isMentoriceZoom()
      })
      .then(data => {
        this.overview = data;
      });
  },
  methods: {
    isMentoriceZoom(): any {
      const index = this.platforms.findIndex(
        (e: any) => e.id === parseInt(this.data.mentoring_platform_id)
      );
      //@ts-ignore
      return index > -1 && this.platforms[index].name == "Hosted by Mentorice";
    },
    getData(item: any): any {
      if (item.includes("_id")) {
        return this.getOptionData(item);
      }
      if (item === "start_time") {
        return this.getStartTime();
      }
      if (["is_certificate", "has_guest_users"].includes(item)) {
        return this.data[item] ? "Yes" : "No";
      }
      if (item in this.data) {
        return this.data[item];
      }
    },
    getStartTime() {
      return `${this.data["start_time"]} ${this.data["time_indicator"]}`;
    },
    getOptionData(item: any): any {
      const types = {
        language_id: "languages",
        mentoring_platform_id: "platforms",
        course_audience_id: "audiences"
      } as any;
      //@ts-ignore
      if (types[item] && this[types[item]]) {
        //@ts-ignore
        const index = this[types[item]].findIndex(
          (i: any) => i.id === this.data[item]
        );
        if (index > -1) {
          //@ts-ignore
          return this[types[item]][index].name;
        }
      }
    },
    onSubmit() {
      this.submitBtnLoading = true;

      this.isPublished ? this.update() : this.create();
    },
    create() {
      this.$store
        .dispatch("course/CREATE_MENTORCAST", this.data)
        .then(id => {
          this.submitBtnLoading = false;
          (this as any).$alertify.success(
            (this as any).$t("courseHero.courseCreatedSuccessMessage")
          );
          this.$router.push(`/courses/${id}`);
        })
        .catch(error => {
          this.submitBtnLoading = false;
          if (error.response.status === 422) {
            this.$emit("errors", error.response.data.errors);
          }
        });
    },
    update() {
      this.$store
        .dispatch("course/UPDATE_MENTORCAST", {
          id: this.$route.params.id,
          formData: this.data
        })
        .then(id => {
          this.submitBtnLoading = false;
          (this as any).$alertify.success(
            (this as any).$t("courseHero.courseUpdatedSuccessMessage")
          );
          this.$router.push(`/courses/${id}`);
        })
        .catch(error => {
          this.submitBtnLoading = false;
          if (error.response.status === 422) {
            this.$emit("errors", error.response.data.errors);
          }
        });
    },
    editCourse() {
      this.$emit("edit-course");
    }
  }
});
