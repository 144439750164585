
import Vue from "vue";
import CourseForm from "@/components/Course/CourseForm.vue";
import CoursePublish from "@/components/Course/CoursePublish.vue";
import CourseFormLoading from "@/components/Course/CourseFormLoading.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "CourseFormContainer",
  components: {
    CourseForm,
    CoursePublish,
    CourseFormLoading
  },
  props: {
    isPublished: Boolean,
    create: Boolean,
    profile: Object
  },
  data() {
    return {
      url: "",
      data: {
        title: "",
        description: "",
        image: "",
        price: "",
        is_certificate: "",
        is_content_downloadable: "",
        language_id: "",
        categories: [] as string[]
      } as any,
      isLicense: false,
      errors: {} as any,
      readyToPublish: false,
      isCreated: false,
      isAlreadyAgreed: this.isPublished,
      maxSeats: "",
      loading: false,
      skipOverview: !this.create
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency",
      languages: "helper/getLanguages",
      platforms: "helper/getMentoringPlatforms",
      defaultConferencingPlatform: "helper/getDefaultConferencingPlatform",
      courseSettings: "helper/getCourseSettings",
      skipMentorLicenseCastPopup: "course/getSkipCourseLicensePopupValue"
    })
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
    this.$store.dispatch("helper/GET_LANGUAGES");
    this.$store
      .dispatch("helper/GET_DEFAULT_CONFERENCING_PLATFORM")
      .then(() => {
        this.setDefaultPlatform();
      });
    this.$store.dispatch("helper/GET_MENTORING_PLATFORMS");
    this.$store.dispatch("course/GET_SKIP_COURSE_LICENSE_POPUP");
    this.$store.dispatch("helper/GET_COURSE_AUDIENCES");

    if (this.isPublished) {
      this.isLicense = true;
      this.loading = true;
      this.$store
        .dispatch("course/GET_COURSE", this.$route.params.id)
        .then(data => {
          this.readyToPublish = true;
          this.loading = false;
          this.url = data.image_link;
          this.data.id = data.id;
          for (const e of Object.keys(this.data)) {
            if (e !== "categories" && e in data) {
              this.data[e] = data[e];
            }
          }
          this.data.categories = data.categories.map((i: any) => i.title);
        });
    }
  },
  methods: {
    setDefaultPlatform() {
      if (this.defaultConferencingPlatform) {
        this.data.mentoring_platform_id = this.defaultConferencingPlatform;
      }
    },
    resolveDynamic(path: any, obj: any) {
      return path.split(".").reduce(function(prev: any, curr: any) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    update(data: any, url: string) {
      if (this.skipOverview) {
        (this as any).submtCourse(data);
        return;
      }

      //@ts-ignore
      this.validate(data).then(() => {
        this.data = { ...data };
        this.url = url;
        if (this.isLicense) {
          this.readyToPublish = true;
        } else {
          const id = this.platforms.find(
            (e: any) => e.name == "Hosted by Mentorice"
          )?.id;
          if (id === this.data.mentoring_platform_id) {
            this.readyToPublish = true;
          } else {
            (this as any).$refs["courseForm"].showLicense();
          }
        }
      });
    },
    edit() {
      this.isAlreadyAgreed = false;
      this.readyToPublish = false;
      this.isCreated = true;
      //@ts-ignore
      if (this.$refs["courseForm"]) {
        //@ts-ignore
        this.$refs["courseForm"].setData();
      }
    },
    validate(data: any) {
      this.errors = {};
      let store = this.$store.dispatch("course/CREATE_COURSE", {
        ...data,
        validation: true
      });
      if (this.isPublished) {
        store = this.$store.dispatch("course/UPDATE_COURSE", {
          id: this.$route.params.id,
          formData: { ...data, validation: true }
        });
      }
      return new Promise(resolve => {
        store
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
            }
          });
      });
    },
    setErrors(errors: any, isCategoryError = false) {
      if (isCategoryError) {
        this.errors.categories = errors.categories;
      } else {
        this.errors = errors;
      }
      this.readyToPublish = false;
      this.isCreated = true;
    },
    clearCategoryError() {
      if (this.errors.categories) {
        delete this.errors.categories;
      }
    },
    submtCourse(data = null) {
      this.$store
        .dispatch("course/UPDATE_COURSE", {
          id: this.$route.params.id,
          formData: data ?? this.data
        })
        .then(id => {
          (this as any).$alertify.success(
            (this as any).$t("courseHero.courseUpdatedSuccessMessage")
          );
          this.$router.push(`/courses/${id}`);
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.$emit("errors", error.response.data.errors);
          }
        });
    }
  }
});
